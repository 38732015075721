import {Controller} from "@hotwired/stimulus"
import moment from "moment"

export default class extends Controller {
  static targets = ["dateInput", "startTimeInput", "endTimeInput", "startDatetimeInput", "endDatetimeInput"];

  static values = {duration: Number}

  initialize() {
    this.onStartTimeChange = this.onStartTimeChange.bind(this)
    this.recomputeInputs = this.recomputeInputs.bind(this)
  }

  connect() {
    this.startTimeInputTarget.addEventListener("change", this.onStartTimeChange)
    this.startTimeInputTarget.addEventListener("change", this.recomputeInputs)
    this.endTimeInputTarget.addEventListener("change", this.recomputeInputs)
    this.dateInputTarget.addEventListener("change", this.recomputeInputs)
    this.prefillInputs()
  }

  disconnect() {
    this.startTimeInputTarget.removeEventListener("change", this.onStartTimeChange)
    this.startTimeInputTarget.removeEventListener("change", this.recomputeInputs)
    this.endTimeInputTarget.removeEventListener("change", this.recomputeInputs)
    this.dateInputTarget.removeEventListener("change", this.recomputeInputs)
  }

  onStartTimeChange(e) {
    const date = (this.dateInputTarget.value ? moment(this.dateInputTarget.value) : moment()).format("YYYY-MM-DD")
    const startTime = moment(`${date} ${this.startTimeInputTarget.value}`)
    if (this.hasDurationValue && this.durationValue) {
      startTime.add(parseInt(this.durationValue), 'minutes')
      this.endTimeInputTarget.value = startTime.format("HH:mm");
    }
  }

  recomputeInputs() {
    const date = (this.dateInputTarget.value ? moment(this.dateInputTarget.value) : moment()).format("YYYY-MM-DD")
    const startTime = moment(`${date} ${this.startTimeInputTarget.value}`).format("YYYY-MM-DDTHH:mm")
    const endTime = moment(`${date} ${this.endTimeInputTarget.value}`).format("YYYY-MM-DDTHH:mm")
    this.startDatetimeInputTarget.value = startTime
    this.endDatetimeInputTarget.value = endTime
  }

  prefillInputs() {
    if (this.startDatetimeInputTarget.value && this.endDatetimeInputTarget.value) {
      const startTime = moment(this.startDatetimeInputTarget.value)
      const endTime = moment(this.endDatetimeInputTarget.value)
      this.dateInputTarget.value = startTime.format("YYYY-MM-DD")
      this.startTimeInputTarget.value = startTime.format("HH:mm")
      this.endTimeInputTarget.value = endTime.format("HH:mm")
    }
  }
}
