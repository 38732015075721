import {Controller} from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["taskableTypeInput", "taskableIdInput"]
  static values = {taskableTypeUrls: Object}

  #taskableIdController
  #taskableTypeController
  connect() {
  }
  updateInputs() {
    if (!this.taskableIdController)
      return

    // On met a jour l url ajax si changement de type
    const url = this.taskableTypeUrlsValue[this.taskableTypeValue]

    console.log(this.taskableTypeUrlsValue)
    console.log(this.taskableTypeValue)

    if (url != this.taskableIdController.ajaxUrl) {
      this.taskableIdController.clearValue()
      this.taskableIdController.setAjaxUrl(url)
    }
  }
  get taskableIdController() {
    this.#taskableIdController ||= this.taskableIdInputTarget["form--collection-select--component"]
    return this.#taskableIdController
  }
  get taskableTypeValue() {
    this.#taskableTypeController ||= this.taskableTypeInputTarget["form--collection-select--component"]
    return this.#taskableTypeController?.value
  }
}
