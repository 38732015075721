import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from 'stimulus-use'

// import confetti from "canvas-confetti";
import cookie from "cookiejs";

export default class extends Controller {
  static targets = ["content", "closeBtn", "timerBar"]; /* "overlay",*/
  static values = { name: String, cookie: String, closable: Boolean, removeOnClose: Boolean, confetti: Boolean, timer: Number };

  initialize() {
    // Move component to body end
    document.body.appendChild(this.element);
    this.element[this.identifier] = this;
    this.close = this.close.bind(this);
  }

  connect() {
    // useClickOutside(this, { element: this.contentTarget })
    this.closeBtnTargets.forEach(
      ((btn) => {
        btn.addEventListener("click", this.close);
      }).bind(this)
    );

    this.bindTogglers();

    if (this.cookieValue && this.element.classList.contains("open")) {
      if (cookie.get(this.cookieValue)) {
        this.element.classList.remove("open");
      }
    }
  }

  disconnect() {
    this.closeBtnTargets.forEach(
      ((btn) => {
        btn.removeEventListener("click", this.close);
      }).bind(this)
    );
  }

  bindTogglers() {
    if (!this.nameValue) return;
    document.querySelectorAll(`[data-modal="${this.nameValue}"]`).forEach(
      ((elem) => {
        const scrollTo = elem.dataset.modalScrollToId;
        elem.addEventListener(
          "click",
          ((e) => {
            this.open(e, scrollTo);
          }).bind(this)
        );
      }).bind(this)
    );
  }

  open(e, scrollToId = null) {
    if (e) e.preventDefault();
    this.element.classList.add("open");

    // Prevent body scroll
    const body = document.querySelector("body");
    body.style.overflow = "hidden";

    if (scrollToId) {
      const scrollElem = this.element.querySelector(`[id="${scrollToId}"]`);
      setTimeout(() => {
        scrollElem.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 400);
    }

    this.resetTimer();
  }

  close(e) {
    this.element.classList.remove("open");

    if (this.cookieValue) cookie.set(this.cookieValue, true);

    // Check if all modals are closed to re-enable body scroll
    const hasModalsOpen = document.querySelectorAll(".oq-modal.open").length > 0;
    if (!hasModalsOpen) {
      const body = document.querySelector("body");
      body.style.overflow = "auto";
    }

    if (this.element.classList.contains("modal--fullscreen")) {
      this.element.classList.add("modal--fullscreen-close");
      setTimeout(() => {
        this.element.classList.remove("modal--fullscreen-close");
      }, 500);
    }

    if (this.hasRemoveOnCloseValue && this.removeOnCloseValue == true) {
      this.element.remove()
    }
  }

  resetTimer() {
    if (this.timerValue) {
      this.timer = 0;
      this.timerInterval = setInterval(this.fillTimer, 10);
    }
  }

  clickOutside(e) {
    if (!this.closableValue)
      return;
    e.preventDefault()
    this.close();
  }
}
